import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const AnalyticsHandler = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize("G-226WPEV4P8");
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname + location.search,
        });
    }, [location]);

    return null;
};

export default AnalyticsHandler;
